<template>
    <div>
        <div class="section">
            <div class="row m-12 justify-content-center align-items-center">
                <div class="col-11">
                    <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center" style="width:100%;">
                            <thead>
                                <tr>
                                    <th scope="col">Источник</th>
                                    <th scope="col">Алгоритм</th>
                                    <th scope="col">Время</th>
                                    <th scope="col">Ссылка на файл</th>
                                    <th scope="col">Название файла</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(x, ix) in report_list">
                                    <td>{{ x.source }}</td>
                                    <td>{{ x.algorithm }}</td>
                                    <td>{{ x.report_date }}</td>
                                    <td>
                                        <a @click="getExcel(x.id)" class="btn btn-icon btn-outline-primary"
                                            :id="x.id"><download-icon class="fea icon-sm"></download-icon></a>
                                    </td>
                                    <td>{{ x.file_name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import store from "@/store/store";
import { DownloadIcon } from "vue-feather-icons";

export default {
    name: "topic-modelling-rawdata",
    components: {
        DownloadIcon
    },
    data() {
        return {
            in_excel: {
                left: [],
                right: []
            },
            report_list: [],
            in_sql: {
                id: 100000,
            },
        }
    },

    mounted() {
        this.getHistory();
    },

    methods: {
        async getExcelName(x) {
            const filename = this.axios({
                method: 'post',
                url: `${this.$hostname_ba}/topic-modelling/get-rawdata-file-name/${x}`,
            }).then((x) => x.data.filename);
            return filename
        },
        async getExcel(x) {
            const filename = await this.getExcelName(x);

            this.axios({
                method: 'post',
                url: `${this.$hostname_ba}/topic-modelling/get-rawdata-file/${x}`,
                responseType: "blob",
            }).then(function (response) {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const aEle = document.createElement('a');     // Create a label
                const href = window.URL.createObjectURL(blob);       // Create downloaded link
                aEle.href = href;
                aEle.download = filename;  // File name after download
                document.body.appendChild(aEle);
                aEle.click();     // Click to download
                document.body.removeChild(aEle); // Download complete remove element
                window.URL.revokeObjectURL(href); // Release blob object
            });
        },
        getHistory() {
            this.axios({
                method: 'get',
                url: '' + this.$hostname_ba + '/topic-modelling/get-rawdata-history',
            }).then((res) => {
                this.report_list = res.data;
            });
        },
    },
}
</script>

<style scoped></style>